@font-face {
  font-family: 'opendyslexic3regular';
  src:
    url('../../../public/assets/fonts/opendyslexic3-regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/opendyslexic3-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.readability {
  &:not(.teacher-panel),
  .ql-container,
  .node-header {
    font-family: 'opendyslexic3regular' !important;
  }

  .pr2--listColumn-listItem h5 {
    line-height: 1.9em;
  }
  .pr2--listIconWrapper {
    line-height: 1.9em;
  }
  .pr2--panelHeader .pr2--nodePaneName {
    line-height: 60px;
  }
  .pr2--panelHeader .pr2--hubPaneName {
    line-height: 50px;
  }

  .pr2--nodePanelBody,
  .lp--nodePanelUserSection {
    line-height: 36px;
  }

  .purple-guide * {
    font-family: 'opendyslexic3regular';
  }
}
